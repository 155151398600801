<template>
  <div class="overlay"></div>
  <div class="loading-card" v-if="loadingCommand">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../assets/loading.gif" class="img_style" />
        <p class="notiftext_title">{{ this.lang.popup_card7[0] }}</p>
        <p class="notiftext">{{ this.lang.popup_card7[1] }}</p>
      </div>
    </div>
  </div>
  <div class="loading-card" v-if="ringCommandSuccess">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../assets/check.png" class="img_style" />
        <p class="notiftext_title">{{ this.lang.popup_card8[0] }}</p>
        <p class="notiftext">{{ this.lang.popup_card8[1] }}</p>
        <button
          type="submit"
          class="mandev_btn text-center"
          @click="commandRingDone"
        >
          {{ this.lang.popup_card8[2] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serialNumbers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadingCommand: true,
      ringCommandSuccess: false,
      lostmodeload: true,
      lostmodeenabled: false,
      nochanges: false,
      lm_message: "",

      lm_contact: "",

      lm_footnote: "",
      lang: {},
    };
  },
  mounted() {
    this.langchange();
    this.ringDevice();
  },
  methods: {
    async langchange() {
      const response = await fetch("/devicedetails.json");
      const data = await response.json();
      if (!localStorage.locale) {
        localStorage.locale = "ENG";
      }
      this.lang = data[localStorage.locale];
    },
    backManage() {
      this.lostmodeload = false;
      this.lostmodeenabled = false;
      this.nochanges = false;
      this.$emit("closeLostMode");
    },
    ringDevice() {
      const axios = require("axios");

      let data = JSON.stringify({
        device_type_indicator: localStorage.selectedDeviceType,
        serial_number: this.serialNumbers,
        session_id: localStorage.token,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v2/mdm/lost_mode/ring",
        headers: {
          "Content-Type": "application/json", // Set Content-Type for JSON data
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          this.loadingCommand = false;
          this.ringCommandSuccess = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    commandRingDone() {
      this.loadingCommand = false;
      this.ringCommandSuccess = false;
      this.backManage();
    },
  },
};
</script>

<style scoped>
.layout {
  display: flex;
}

.regdevice_contents {
  flex: 1;
  padding-left: 16px;
  font-family: "Century Gothic";
}

.regdevice_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 24px;
  margin-top: 5px;
}

.regdevice_secondary_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 16px;
}

.regdevice_secondary_container {
  margin-top: 15px;
}

.regdevice_container {
  display: flex;
  max-height: 680px;
  justify-content: center;
  overflow-y: auto;
}

/*Card CSS style*/
.card {
  width: 950px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card_content {
  padding: 10px;
}

.device_info {
  display: flex;
}

.device_image {
  width: 180px;
  height: 180px;
  margin-right: 20px;
}

.card_devicename_text {
  font-family: "Century Gothic Bold";
  font-size: 18px;
  margin-bottom: 10px;
}

.card_details_strong {
  font-family: "Century Gothic Bold";
  font-size: 14px;
  margin-bottom: 2px;
}

.card_details_text {
  font-family: "Century Gothic";
  font-size: 14px;
  margin-bottom: 2px;
}

.button_container {
  display: flex;
  height: 55px;
  padding-left: 210px;
  background-color: #fff6ce;
}

.lostmode_btn {
  margin-top: 10px;
  width: 110px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.lostmode_btn:hover {
  filter: brightness(90%);
}

.removebtn {
  padding-left: 370px;
}

.removedevice_btn {
  text-align: center;
  margin-top: 10px;
  width: 110px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  background-color: #fc2929;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.removedevice_btn:hover {
  filter: brightness(90%);
}

.removeprofile_btn {
  text-align: center;
  margin-top: 10px;
  width: 110px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  margin-right: 10px;
  background-color: #000;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.removeprofile_btn:hover {
  filter: brightness(90%);
}

/*Lost Mode Form Style*/
.input_header_style {
  font-family: "Century Gothic Bold";
  font-size: 12px;
  color: #013042;
  margin-bottom: 2px;
}

.lm-counter {
  text-align: right;
  font-size: small;
  padding-right: 30px;
}

.lm-counter-mac {
  text-align: right;
  font-size: small;
  padding-left: 300px;
}

.message_input_form {
  vertical-align: top !important;
  text-align: left !important;
  background-color: #ffffff;
  height: 100px;
  width: 350px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}

.message_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}

.contact_input_form {
  background-color: #ffffff;
  height: 35px;
  width: 350px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}

.contact_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}

::placeholder {
  font-size: 12px;
}

.enable_btn {
  text-align: center;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.enable_btn:hover {
  filter: brightness(90%);
}

.cancel_btn {
  text-align: center;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffffff;
  border-color: #013042;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.cancel_btn:hover {
  filter: brightness(90%);
}

/*Lost Mode Enable Form*/
.lostmode-card {
  position: fixed;
  width: 800px;
  height: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
}

.loastmode-card-macbook {
  position: fixed;
  width: 400px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
}

.lostmode-card-change-date {
  position: absolute;
  width: 600px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: none;
}

.lostmode-content {
  display: flex;
  padding-left: 20px;
}

.lostmode-text {
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: -10px;
}

/*Lost mode confirmation style */
.img_style {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.notiftext_title {
  font-family: "Century Gothic Bold";
  font-size: 12px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext {
  font-family: "Century Gothic";
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.mandev_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.mandev_btn:hover {
  filter: brightness(90%);
}

.change_due_date_btn {
  text-align: center;
  width: 100px;
  height: 25px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  margin-left: 10px;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.change_due_date_btn:hover {
  filter: brightness(90%);
}

.dashboard_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffffff;
  border-color: #013042;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.dashboard_btn:hover {
  filter: brightness(90%);
}

.loading-card {
  position: fixed;
  width: 280px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  font-family: CenturyGothic, sans-serif;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 9998;
  /* Below the loading content */
}

.warning-msg {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.notiftext_title_denied_perms {
  font-family: "Century Gothic Bold";
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.img_style_denied_perms {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  left: 10px;
}
</style>
