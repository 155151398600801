<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 100 !important;">
        <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div style="width: 95%; padding-left: 10px;">
        <div class="dashboard_header">
            <div style="float: left;">
                <div class="dashboard_title">
                    {{ this.lang.title_details[0] }}
                </div>
                <div class="dashboard_text_contents">
                    {{ this.lang.title_details[1] }}<br>
                    {{ this.lang.title_details[2] }}
                </div>
            </div>
            <div style="display:block; max-width: 250px; max-height: 50px; margin-right: 10px; margin-top: 20px; float: right;">
                <p ><b>{{ this.lang.credit_balance }}:</b> {{ this.credit_balance }}<b> USD</b><br><a href="/credit-manager/topup" style="float: right;">Top-up</a></p>
            </div>
        </div>
        <div style="margin-top: 15%; height: 50px; width: 60%; margin-left: auto; margin-right: auto;">
            <div class="searchbar_container">
                <select class="form-control custom_input_form" id="devicename" v-model="topup_value" style="align-self: flex-end;">
                    <option value="100" selected>100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                        </select>
                <b style="padding-left: 10px; padding-top: 5px; align-items: flex-end;">USD</b>
            </div>
            <div class="upload_proof_btn" style="padding-top: 20px;">
                <input type="file" id="browse_file_input" accept=".pdf" v-on:change="handleFileUpload($event)" style="width:0px; display:none"/>
                <button type="button" id="browser" class="submit-btn" onclick="browse_file_input.click()">{{ this.lang.upload_proof_button }}</button>
            </div>
            <div style="padding-top: 10px;">
                <button type="submit" class="submit-btn_on_upload" @click="initiateTopup" :disabled="submit_allowed">{{ this.lang.topup_button }}</button>
            </div>
        </div>
    </div>
    <!--Command Loading-->
    <div class="loading-card" v-if="loadingCommand">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../../assets/loading.gif" class="img_style" style="margin-left: 20px;">
        <p class="notiftext_title">{{ this.lang.popup_card_loading[1] }}</p>
      </div>
    </div>
  </div>
   <!--Successful Topup-->
    <div class="loading-card" v-if="sccessful_topup">
        <div class="loading-content">
        <div class="loading-text">
            <img src="../../assets/check.png" class="img_style">
            <p class="notiftext_title">{{ this.lang.popup_card_success[0] }}</p>
            <p class="notiftext">{{ this.lang.popup_card_success[1] }}</p>
            <div style="display: flex;">
                <button type="submit" class="mandev_btn text-center" style=" margin-top: 20px; float: left;"
                @click="goBack()">{{ this.lang.popup_card_success[2] }}</button>
                <button type="submit" class="mandev_btn text-center" style=" margin-top: 20px; margin-left: 30px; float: right"
                @click="proofOfTransaction()">{{ this.lang.popup_card_success[3] }}</button>
            </div>
        </div>
        </div>
    </div>
     <!--Failed Topup-->
    <div class="loading-card" v-if="failed_topup">
        <div class="loading-content">
        <div class="loading-text">
            <img src="../../assets/multiply.png" class="img_style">
            <p class="notiftext_title">{{ this.lang.popup_card_fail[0] }}</p>
            <p class="notiftext">{{ this.lang.popup_card_fail[1] }}</p>
            <button type="submit" class="mandev_btn text-center" style=" margin-top: 20px;"
            @click="goBack">{{ this.lang.popup_card_fail[2] }}</button>
        </div>
        </div>
    </div>
    <!--Access denied-->
    <div class="loading-card" v-if="page_unavailable">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../../assets/multiply.png" class="img_style" style="margin-left: 15px;">
                    <p class="notiftext_title" style="margin-left: 20px;">{{
                        this.lang.popup_card_access_denied[0] }}</p>
                    <p class="notiftext">{{ this.lang.popup_card_access_denied[1] }}</p>
                    <button type="submit" class="mandev_btn text-center"
                        style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                        this.lang.popup_card_access_denied[2] }}</button>
                </div>
            </div>
        </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>
<script>
    import SideNavbar from '../SideNavbar.vue';
    import BottomFooter from '../BottomFooter.vue';

    export default {
        components: {
            SideNavbar,
            BottomFooter,
        },
        data() {
            return{
                loading: false,
                loadingCommand: false,
                sccessful_topup: false,
                failed_topup: false,
                page_unavailable: false,
                receipt_file: '',
                selected_transaction_id: -1,
                topup_value: "100",
                submit_allowed: true,
                lang:{
                    title_details: [],
                    popup_card_success: [],
                    popup_card_fail: [],
                    popup_card_access_denied: [
                        "Access Denied",
                        "Please return to the homepage",
                        "OK"
                    ],
                    upload_proof_button: "",
                    topup_button: ""
                },
                credit_balance: "",
            };
        },
        mounted() {
            this.retrieveBalance();
        },
        methods: {
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("../topup-page.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
            },
            handleFileUpload(event) {
                this.receipt_file = event.target.files[0];
                this.submit_allowed = false;
            },
            initiateTopup() {
                // Retrieve data
                this.loading = true;
                this.loadingCommand = true;
                let form_data = new FormData();
                form_data.append('session_id', localStorage.token);
                form_data.append('topup_amount', this.topup_value);
                form_data.append('file', this.receipt_file);
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/topup/initiate',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: form_data
                };
                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.loadingCommand = false;
                        this.sccessful_topup = true;
                        this.selected_transaction_id = response.data.transaction_id;
                    }
                    else {
                        this.loadingCommand = false;
                        this.failed_topup = true;
                    }
                })

            },  
            retrieveBalance() {
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/balance',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    data: JSON.stringify({
                        session_id: localStorage.token,
                    })
                };

                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.credit_balance = response.data["credit_balance"]
                        localStorage.credit_balance = this.credit_balance;
                    }
                })
            },
            proofOfTransaction() {
                this.$router.replace('/credit-manager/status?transaction_id='+this.selected_transaction_id)
            },
            goBack() {
                this.$router.replace('/credit-manager');
            }
        }
    }
</script>
<style scoped>

    select { text-align-last: right; }

    option { direction: rtl; }

    input { 
        text-align: right; 
    }

    .loading-card {
        position: fixed;
        width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 28px;
        filter: brightness(98%);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .loading-text {
        justify-items: center;
        display: grid;
        font-family: CenturyGothic, sans-serif;
        text-align: center;
    }

    .layout{
        display: flex;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }

    .notiftext {
        font-family: 'Century Gothic';
        text-align: center !important;
        font-size: 12px;
        color: #000000;
        margin-bottom: 8px;
    }

    .mandev_btn {
        text-align: center;
        width: 150px;
        height: 30px;
        margin-bottom: 5px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        border: none;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }

    .mandev_btn:hover {
        filter: brightness(90%);
    }

    .notiftext_title {
       font-family: 'Century Gothic Bold';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2px;
    }

    .img_style {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        left: 10px;
    }

    .dashboard_header {
       max-height: 200px;
       flex: 1;
       padding-left: 16px;
    }

    .dashboard_title {
       max-width: 200px;
       font-family: 'Century Gothic Bold';
       font-weight: bold;
       font-size: 28px;
       margin-top: 11px;
    }
    
    .dashboard_text_contents {
       font-family: 'Century Gothic';
       font-size: 14px;
       margin-top: 10px;
    }

    .searchbar_container{
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .submit-btn{
        align-items: center;
        margin-left: 36%;
        margin-top: 10px;
        width: 200px;
        height: 50px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 11px;
        border: none;
        border-radius: 15px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }
    .submit-btn:hover{
        filter: brightness(90%);
    }
    .submit-btn_on_upload {
        align-items: center;
        margin-left: 36%;
        margin-top: 10px;
        width: 200px;
        height: 50px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 11px;
        border: none;
        border-radius: 15px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }
    .submit-btn_on_upload:hover {
        align-items: center;
        margin-left: 36%;
        margin-top: 10px;
        width: 200px;
        height: 50px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 11px;
        border: none;
        border-radius: 15px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }
    .submit-btn_on_upload:disabled {
        background-color: black;
        color: #ffffff;
    }

</style>