<template>
    <div class="login_body container-fluid">
        <div class="overlay" v-if="loading"></div>
        <div class="row justify-content-center align-items-center" style="height: 100vh;">
            <div class="col-md-6">
                <div class="text-center">
                    <img src="../assets/new_logo.png" alt="Logo" class="img_style" @click="langswap()"/>
                    <p class="title_text">{{ lang.title_text }}</p>
                </div>
                <form @submit.prevent="login">
                    <div class="form-group">
                        <label class="input_header_style" for="username">{{ lang.username }}</label>
                        <input type="text" class="form-control custom_input" id="username" :placeholder="lang.username_hint" v-model="username" required />  
                    </div>
                    <div class="form-group" style="margin-top: 15px;">
                        <label class="input_header_style" for="password">{{ lang.password }}</label>
                        <input type="password" class="form-control custom_input" id="password" :placeholder="lang.password_hint" v-model="password" required />
                    </div>
                    <div class="text-center">
                        <button type="submit" class="login_btn">{{ lang.login_button }}</button>
                        <div v-if="tryagainWarning" class="warning-msg text-center">{{ lang.login_warning }}</div>
                    </div>
                </form>
                <div style="text-align: center; margin-top: 100px !important;">
                    <p>{{ lang.powered_by }}</p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    data(){
        return{
            username: '',
            password: '',
            loading: false,
            tryagainWarning: false,
            lang : [],
            langmode : "ENG"
        };
    },
    methods:{
        login(){
            console.log('Logging in with:', this.username, this.password);
            this.loading = true;
            if(this.username && this.password){
                const axios = require('axios');
                let data = JSON.stringify({
                    "username": this.username,
                    "password": this.password
                });
                let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: process.env.VUE_APP_BACKEND_URL+'/v1/dashboard/login',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
                };

                axios.request(config)
                .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data["200"] != undefined){
                    console.log('Login Successful');
                    localStorage.token = response.data["200"]
                    localStorage.currentUser = this.username
                    localStorage.role = response.data.role
                    localStorage.bearer_token = response.data.bearer_token
                    this.loading = false
                    this.tryagainWarning = false
                    this.$router.replace({path:'/dashboard'})
                }
                else{
                    this.loading = false
                    this.tryagainWarning = true
                }
                })
                .catch((error) => {
                console.log(error);
                this.loading = false
                this.tryagainWarning = true
                });
            }
            else if(!this.username){
                console.log('Please fill in username')
                this.loading = false
                this.tryagainWarning = false
            }
            else if(!this.password){
                console.log('Please fill in password')
                this.loading = false
                this.tryagainWarning = false
            }
            else{
                console.log('Login Errorr')
                this.loading = false
                this.tryagainWarning = true
            }
        },
        async langchange(val){
            this.langmode = val
            const response = await fetch("/login.json");
            const data = await response.json();
            this.lang = data[this.langmode];
        },
        langswap(){
            var langmode
            if (this.langmode === "ENG"){
                langmode = "BM"
            }
            else{
                langmode = "ENG"
            }
            this.langchange(langmode)
        }
    },
    mounted() {
        this.langchange("ENG");
        localStorage.clear();
    },
};
</script>

<style scoped>
@import '../fonts/fonts.css';

    .title_text{
        font-weight: 500;
        font-size: 16px;
        font-family: 'Century Gothic', sans-serif;
        margin-top: 10px;
    }
    .img_style{
        height: 80px;
        width: 300px;
    }
    .login_body{
        background-color: #ffd600;
        height: 100%;
        width: 100%;
        margin-top: 0px;
        z-index: 10;
        font-family: 'Century Gothic', sans-serif;
    }
    .login_btn{
        text-align: center;
        margin-top: 30px;
        width: 140px;
        height: 30px;
        font-family: 'Century Gothic', sans-serif;
        font-size: 10px;
        border-color: #013042;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffffff;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }
    .login_btn:hover{
        filter: brightness(90%);
    }
    .input_header_style{
        font-weight: 500;
        font-size: 14px;
        color: #013042;
        margin-bottom: 2px;
    }
    .custom_input{
        background-color: #ffffff;
        height: 35px;
        width: 100%;
        text-decoration: none solid rgba(0, 0, 0, 0.87);
        border-color: rgba(1, 48, 66, 0.2) !important;
        word-spacing: 0px;
        letter-spacing: 0.131118px;
        font-size: 12px !important;
        box-sizing: border-box;
        outline: rgba(0, 0, 0, 0.87) none 0px;
    }
    .custom_input:focus{
        outline: none !important;
        box-shadow: none !important;
        border-color: rgba(1, 48, 66, 0.8) !important;
        border-width: 2.5px;
        background-color: #ffffff;
        color: #000000;
        font-size: 12px;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }
    .warning-msg {
        color: red;
        font-weight: bold;
        font-size: 12px;
        margin-top: 10px;
    }
    ::placeholder{
        font-size: 12px;
    }
    @media(max-width: 280px){
        .img_style{
            width: 250px;
            height: 70px;
        }
    }
</style>