<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 100 !important;">
        <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="dashboard_contents">
        <div class="dashboard_title">
            {{ this.lang.title_details[0] }}
        </div>
        <div class="dashboard_text_contents">
            {{ this.lang.title_details[1] }}
        </div>
        <input type="text" class="custom_input_form" id="devicename" :placeholder="this.lang.search_function[0]" v-model="transaction_id" />
        <button type="submit" class="search_btn" @click="searchTransactionData">{{ this.lang.search_function[1] }}</button>
        <div class="table_container">
        <table class="table_styles">
            <thead class="table_header">
                <tr>
                <th class="tablehead_text">{{ this.lang.table_header[0] }}</th>
                <th class="tablehead_text">{{ this.lang.table_header[1] }}</th>
                <th class="tablehead_text">{{ this.lang.table_header[2] }}</th>
                <th class="tablehead_text">{{ this.lang.table_header[3] }}</th>
                <th class="tablehead_text">{{ this.lang.table_header[4] }}</th>
                <th class="tablehead_text">{{ this.lang.table_header[5] }}</th>
                <th class="tablehead_text">{{ this.lang.table_header[6] }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(input_data, index) in credit_logs" :key="index"
                :class="{ 'odd_row': index % 2 === 0, 'even_row': index % 2 !== 0 }" class="table_body_border">

                <td class="tablecolumn_text">{{ input_data[0] }}</td>
                <td class="tablecolumn_text">{{ input_data[2] }}</td>
                <td class="tablecolumn_text">{{ ( input_data[3] == 1 ) ? "CREDIT" : "DEBIT" }}</td>
                <td class="tablecolumn_text">{{ input_data[4] }}</td>
                <td class="tablecolumn_text">{{ input_data[10] }}</td>
                <td>
                    <button type="submit" class="manageacc_btn" @click="viewMoreTransctionOption(input_data[0])">{{
                    this.lang.manage_button }}</button>
                </td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
    <div style="display:block; max-width: 250px; max-height: 50px; margin-right: 10px; margin-top: 15px;">
        <p ><b>{{ this.lang.credit_balance }}:</b> {{ this.credit_balance }}<b> USD</b><br><a href="/credit-manager/topup" style="float: right;">Top-up</a></p>
    </div>
    <!--Access denied-->
    <div class="loading-card" v-if="page_unavailable">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                    <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                        this.lang.popup_card_access_denied[0] }}</p>
                    <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                    <button type="submit" class="mandev_btn text-center"
                        style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                        this.lang.popup_card_access_denied[2] }}</button>
                </div>

            </div>
        </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>
<script>
    import SideNavbar from '../SideNavbar.vue';
    import BottomFooter from '../BottomFooter.vue';

    export default {
        components: {
            SideNavbar,
            BottomFooter,
        },
        data() {
            return{
                loading: false,
                page_unavailable: false,
                lang:{
                    popup_card_access_denied: [
                        "Access Denied",
                        "Please return to the homepage",
                        "OK"
                    ],
                    credit_balance: "",
                    title_details: [],
                    table_header: [],
                    search_function: []
                },
                credit_balance: 0.00,
                transaction_id: '',
                credit_logs: []
            };
        },
        mounted() {
            this.retrieveBalance();
            this.retrieveTransactionData();
        },
        methods: {
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("../creditdashboard.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
            },
            retrieveBalance() {
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/balance',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    data: JSON.stringify({
                        session_id: localStorage.token,
                    })
                };

                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.credit_balance = response.data["credit_balance"]
                        localStorage.credit_balance = this.credit_balance;
                    }
                })
            },
            retrieveTransactionData() {
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/logs',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    data: JSON.stringify({
                        session_id: localStorage.token,
                    })
                };

                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response.data)
                        this.credit_logs = response.data['credit_logs']
                    }
                })
            },
            searchTransactionData() {
                const axios = require('axios');
                const data = JSON.stringify({
                    transaction_id: this.transaction_id
                });
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/logs/search',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.credit_logs = response.data['credit_logs']
                    }
                })
            },
            handleTopup() {
                this.$router.replace('/credit-manager/topup')
            },
            viewMoreTransctionOption(transaction_id) {
                this.$router.replace('/credit-manager/status?transaction_id='+transaction_id)
            },
            goBack() {
                this.$router.replace('/dashboard');
            }
        }
    }
</script>
<style scoped>

    .custom_input_form{
        background-color: #ffffff;
        height: 35px;
        width: 300px;
        text-decoration: none solid rgba(0, 0, 0, 0.87);
        border-color: rgba(1, 48, 66, 0.2) !important;
        word-spacing: 0px;
        letter-spacing: 0.131118px;
        font-size: 12px !important;
        box-sizing: border-box;
        outline: rgba(0, 0, 0, 0.87) none 0px;
    }

    .custom_input_form:focus{
        outline: none !important;
        box-shadow: none !important;
        border-color: rgba(1, 48, 66, 0.8) !important;
        border-width: 2.5px;
        background-color: #ffffff;
        color: #000000;
        font-size: 12px;
    }

    .search_btn {
        margin-left: 10px;
        width: 100px;
        height: 35px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 11px;
        border: none;
        border-radius: 5px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }

    .search_btn:hover {
        filter: brightness(90%);
    }

    .loading-card {
        position: fixed;
        width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 28px;
        filter: brightness(98%);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .loading-text {
        justify-items: center;
        display: grid;
        font-family: CenturyGothic, sans-serif;
        text-align: center;
    }

    .layout{
        display: flex;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }

    .notiftext_denied_perms {
        font-family: 'Century Gothic';
        text-align: center !important;
        font-size: 12px;
        color: #000000;
        margin-bottom: 8px;
    }

    .input-search {
        min-width: 300px;
        max-width: 500px;
        margin-right: 10px;
    }

    .mandev_btn {
        text-align: center;
        width: 150px;
        height: 30px;
        margin-bottom: 5px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        border: none;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }

    .mandev_btn:hover {
        filter: brightness(90%);
    }

    .manageacc_btn {
    margin-left: 10px;
    width: 110px;
    height: 35px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 500;
    }   

    .manageacc_btn:hover {
        filter: brightness(90%);
    }

    .notiftext_title_denied_perms {
       font-family: 'Century Gothic Bold';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2px;
    }

    .img_style_denied_perms {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        left: 10px;
    }

    .dashboard_contents {
       flex: 1;
       padding-left: 16px;
    }

    .dashboard_title {
       font-family: 'Century Gothic Bold';
       font-weight: bold;
       font-size: 28px;
       margin-top: 11px;
    }
    
    .dashboard_text_contents {
       font-family: 'Century Gothic';
       font-size: 14px;
       margin-top: 10px;
    }

    .table_header {
        background-color: #cccccc;
        text-align: center;
        border-bottom: 1px solid #989898;
    }

    .table_body_border {
        border-bottom: 1px solid #989898;
    }

    .tablehead_text {
        font-family: "Century Gothic Bold", sans-serif;
        font-size: 14px;
        text-align: center;
    }

    .table_container {
        margin-top: 25px;
        max-width: 1500px;
        max-height: 650px;
        overflow-y: auto;
    }

    .table_container::-webkit-scrollbar {
        width: 8px;
    }

    .table_container::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    }

    .table_container::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: 4px;
    }

    .table_styles {
        width: 100%;
    }

    thead tr {
        height: 50px;
    }

    th,
    td {
        text-align: center;
        padding: 16px;
    }

    .odd_row {
        background-color: #ffffff;
    }

    .even_row {
        background-color: #e6e6e6;
    }

    .table_header {
        background-color: #cccccc;
        text-align: center;
        border-bottom: 1px solid #989898;
    }

    .table_body_border {
        border-bottom: 1px solid #989898;
    }

    .tablehead_text {
        font-family: "Century Gothic Bold", sans-serif;
        font-size: 14px;
        text-align: center;
    }

</style>