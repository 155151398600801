<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 100 !important;">
        <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div style="width: 95%;">
        <div class="dashboard_header">
            
            <div style="float: left;">
                <div>
                    <BackButton @click="goBack" :message="this.lang.back_button"></BackButton>
                </div>
                <div class="dashboard_title">
                    {{ this.lang.title_details[0] }}
                </div>
                <div class="dashboard_text_contents">
                    {{ this.lang.title_details[1] }}
                </div>
            </div>
            <div style="display:block; max-width: 250px; max-height: 50px; margin-right: 10px; margin-top: 20px; float: right;">
                <p ><b>{{ this.lang.credit_balance }}:</b> {{ this.credit_balance }}<b> USD</b><br><a href="/credit-manager/topup" style="float: right;">Top-up</a></p>
            </div>
        </div>
        <div class="dashboard_content">
            <div class="dashboard_item_field">
                <b style="font-style:'Century Gothic Bold';">{{ this.lang.transaction_headers[0] }}</b> #{{ this.transaction_info_list[0] }}
            </div>
            <div class="dashboard_item_field">
                <b style="font-style:'Century Gothic Bold';">{{ this.lang.transaction_headers[1] }}</b> {{ this.transaction_info_list[1] }}
            </div>
            <div class="dashboard_item_field">
                <b style="font-style:'Century Gothic Bold';">{{ this.lang.transaction_headers[2] }}</b> {{ this.transaction_info_list[2] }} <b>USD</b>
            </div> 
            <div class="dashboard_item_field">
                <b style="font-style:'Century Gothic Bold';">{{ this.lang.transaction_headers[3] }}</b> {{ this.transaction_info_list[3] }}
            </div>
            <div class="dashboard_item_field">
                <b style="font-style:'Century Gothic Bold';">{{ this.lang.transaction_headers[4] }}</b> {{ this.transaction_info_list[4] }}
            </div>  
            <div class="dashboard_item_field">
                <b style="font-style:'Century Gothic Bold';">{{ this.lang.transaction_headers[5] }}</b> {{ this.transaction_info_list[5] }}
            </div>
            <div class="dashboard_item_field">
                <b style="font-style: 'Century Gothic';">{{ this.lang.copyright }}</b>
            </div>
            <div v-if="this.transaction_info_list[5] === 'PENDING PROOF-OF-TRANSACTION'" class="upload_proof_btn">
                <input type="file" id="browse_file_input" accept=".pdf" v-on:change="handleFileUpload($event)" style="width:0px; display:none"/>
                <button type="button" id="browser" class="submit-btn" onclick="browse_file_input.click()">{{ this.lang.upload_proof_button }}</button>
            </div>    
        </div>   
    </div>
    </div>
    <!--Upload Successful-->
    <div class="loading-card" v-if="file_upload_success">
        <div class="loading-content">
            <div class="loading-text">
                <img src="../../assets/check.png" class="img_style_denied_perms" style="margin-left: 15px;">
                <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                    this.lang.popup_card_upload_success[0] }}</p>
                <p class="notiftext_denied_perms">{{ this.lang.popup_card_upload_success[1] }}</p>
                <button type="submit" class="mandev_btn text-center"
                    style="margin-left: 15px; margin-top: 20px;" @click="refreshPage">{{
                    this.lang.popup_card_upload_success[2] }}</button>
            </div>
        </div>
    </div>
    <!--Upload Failed-->
    <div class="loading-card" v-if="file_upload_fail">
        <div class="loading-content">
            <div class="loading-text">
                <img src="../../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                    this.lang.popup_card_upload_failed[0] }}</p>
                <p class="notiftext_denied_perms">{{ this.lang.popup_card_upload_failed[1] }}</p>
                <button type="submit" class="mandev_btn text-center"
                    style="margin-left: 15px; margin-top: 20px;" @click="refreshPage">{{
                    this.lang.popup_card_upload_failed[2] }}</button>
            </div>
        </div>
    </div>    
    <!--Access denied-->
    <div class="loading-card" v-if="page_unavailable">
        <div class="loading-content">
            <div class="loading-text">
                <img src="../../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                    this.lang.popup_card_access_denied[0] }}</p>
                <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                <button type="submit" class="mandev_btn text-center"
                    style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                    this.lang.popup_card_access_denied[2] }}</button>
            </div>
        </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>
<script>
    import SideNavbar from '../SideNavbar.vue';
    import BottomFooter from '../BottomFooter.vue';
    import BackButton from '../BackButton.vue';

    export default {
        components: {
            SideNavbar,
            BottomFooter,
            BackButton
        },
        data() {
            return{
                loading: false,
                page_unavailable: false,
                receipt_file: '',
                file_upload_fail: false,
                file_upload_success: false,
                lang:{
                    back_button: "",
                    title_details: [],
                    transaction_headers: [],
                    upload_proof_button: "",
                    popup_card_access_denied: [
                        "Access Denied",
                        "Please return to the homepage",
                        "OK"
                    ],
                    copyright: "",
                    popup_card_upload_failed: [],
                    popup_card_upload_success: [],
                    credit_balance: localStorage.credit_balance,

                },
                transaction_info_list: [
                    "#1000000000",
                    "400.00",
                    "10th September 2024, 14:20pm GMT+8",
                    "12th September 2024, 14:20pm GMT+8",
                    "APPROVED"
                ]
            };
        },
        mounted() {
            this.get_transaction_detail()
            this.retrieveBalance()
        },
        methods: {
            retrieveBalance() {
                const axios = require('axios');
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/balance',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    data: JSON.stringify({
                        session_id: localStorage.token,
                    })
                };

                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.credit_balance = response.data["credit_balance"]
                        localStorage.credit_balance = this.credit_balance;
                    }
                })
            },
            get_transaction_detail() {
                const axios = require('axios');
                const data = JSON.stringify({
                    transaction_id: this.$route.query.transaction_id
                });
                console.log(this.$route.query)
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/detail',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                .then((response) => {
                    if (response.status === 200) {
                        this.transaction_info_list = response.data.transaction_info
                        console.log(response.data.transaction_info)
                    }
                    else {
                        console.log("Invalid transaction info!")
                    }
                    console.log(this.transaction_info_list)
                })
            },
            handleFileUpload( event ){
                this.loading = true;
                const axios = require('axios');
                this.receipt_file = event.target.files[0];
                console.log(this.receipt_file);
                let form_data = new FormData();
                form_data.append('file', this.receipt_file);
                form_data.append('transaction_id', this.$route.query.transaction_id);
                form_data.append('session_id', localStorage.token);
                if (!(this.receipt_file && this.receipt_file.type === "application/pdf")) {
                    this.file_upload_fail = true;
                }
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/credit/topup/upload-proof',
                    headers: { 
                        'Content-Type': 'multipart/form-data'
                    },
                    data : form_data
                };
                axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.file_upload_success = true;
                    } else {
                        this.file_upload_fail = true;
                    }
                });
            },
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("../creditstatus.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
            },
            goBack() {
                this.$router.replace('/credit-manager');
            },
            refreshPage() {
                window.location.reload();
            }
        }
    }
</script>
<style scoped>

    .loading-card {
        position: fixed;
        width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 28px;
        filter: brightness(98%);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .loading-text {
        justify-items: center;
        display: grid;
        font-family: CenturyGothic, sans-serif;
        text-align: center;
    }

    .layout{
        display: flex;
        width: 100%;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }

    .notiftext_denied_perms {
        font-family: 'Century Gothic';
        text-align: center !important;
        font-size: 12px;
        color: #000000;
        margin-bottom: 8px;
    }

    .mandev_btn {
        text-align: center;
        width: 150px;
        height: 30px;
        margin-bottom: 5px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        border: none;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }

    .mandev_btn:hover {
        filter: brightness(90%);
    }

    .notiftext_title_denied_perms {
       font-family: 'Century Gothic Bold';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2px;
    }

    .img_style_denied_perms {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        left: 10px;
    }

    .dashboard_header {
       flex: 1;
       width: 100%;
       padding-left: 16px;
       max-height: 200px;
    }

    .dashboard_title {
       font-family: 'Century Gothic Bold';
       font-weight: bold;
       font-size: 28px;
       margin-top: 11px;
    }

    .dashboard_text_contents {
       font-family: 'Century Gothic';
       font-size: 14px;
       margin-top: 10px;
    }

    .dashboard_item_field {
        font-family: 'Century Gothic';
    }

    .dashboard_content {
        max-height: 100px;
        margin-top: 130px;
        margin-left: 15px;
        font-size: 20px;
        width: 100%;
    }

    .upload_proof_btn {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    .submit-btn{
        align-items: center;
        margin-top: 100px;
        margin-left: 35%;
        width: 300px;
        height: 75px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 19px;
        border: none;
        border-radius: 15px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }

    
    .submit-btn:hover{
        filter: brightness(90%);
    }

</style>