<template>
  <div class="layout">
    <div class="overlay" v-if="overlayup"></div>
    <div style="z-index: 100 !important">
      <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="regdevice_contents">
      <BackButton @click="goBack" :message="this.lang.back_button"></BackButton>
      <div class="regdevice_header">
        {{ this.lang.title }}
      </div>
      <div class="regdevice_secondary_container">
        <p class="regdevice_secondary_header">{{ this.lang.second_title }}</p>
      </div>
      <div class="regdevice_container">
        <div class="card">
          <div class="card_content">
            <div class="device_info">
              <img
                v-if="isMacbook()"
                src="../assets/macbook_placeholder.png"
                alt="Device Picture1"
                class="device_image"
              />
              <img
                v-else-if="isiPad()"
                src="../assets/zlatko_plamenov_ipadicon.png"
                alt="Device Picture2"
                class="device_image"
                style="
                  width: 150px;
                  height: 170px;
                  padding-top: 20px;
                  margin-left: 30px;
                  padding-right: 30px;
                "
              />
              <img
                v-else
                src="../assets/phone_placeholder.png"
                alt="Device Picture3"
                class="device_image"
              />
              <div>
                <h2 class="card_devicename_text">{{ modelname }}</h2>
                <p class="card_details_text">
                  <strong class="card_details_strong"
                    >{{ this.lang.details[0] }}:</strong
                  >{{ storage }}
                </p>
                <p class="card_details_text" v-if="select_type === 'consumer'">
                  <strong class="card_details_strong"
                    >{{ this.lang.details[1] }}:</strong
                  >
                  {{ names }}
                </p>
                <p class="card_details_text">
                  <strong class="card_details_strong"
                    >{{ this.lang.details[2] }}:</strong
                  >
                  {{ selected }}
                </p>
                <p class="card_details_text" v-if="select_type === 'consumer'">
                  <strong class="card_details_strong"
                    >{{ this.lang.details[3] }}:</strong
                  >
                  {{ msisdns }}
                </p>
                <p class="card_details_text" v-if="select_type === 'consumer'">
                  <strong class="card_details_strong"
                    >{{ this.lang.details[4] }}:</strong
                  >
                  {{ contacts }}
                  <button
                    type="submit"
                    class="change_due_date_btn text-center"
                    @click="onOpenPopupToChangeDue(selected)"
                  >
                    {{ this.lang.popup_card2[4] }}
                  </button>
                </p>
                <p class="card_details_text">
                  <strong class="card_details_strong"
                    >{{ this.lang.details[5] }}:</strong
                  >
                  {{ last_trigger }}
                </p>
                <p class="card_details_text">
                  <strong class="card_details_strong">
                    {{
                      isMacbook()
                        ? this.lang.details[7]
                        : isiPad()
                        ? this.lang.details[8]
                        : this.lang.details[6]
                    }}:
                  </strong>
                  {{ contract_status }}
                </p>
              </div>
            </div>
          </div>
          <div class="button_container">
            <button type="submit" class="lostmode_btn" @click="enableLostMode">
              {{ this.lang.enable_button }}
            </button>
            <div class="removebtn">
              <button
                type="submit"
                class="removeprofile_btn"
                @click="onRemoveProfile"
              >
                {{ this.lang.remove_profile }}
              </button>
              <button
                type="submit"
                class="removedevice_btn"
                @click="onRemoveDevice"
              >
                {{ this.lang.remove_button }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Lost Mode Enable Form-->
      <div
        :class="{
          'lostmode-card': !isMacbook(),
          'loastmode-card-macbook': isMacbook(),
        }"
        v-if="lostmodeload"
      >
        <div class="row">
          <div class="lostmode-text">
            {{ this.lang.popup_card1[0] }}
          </div>
          <div class="lostmode-content">
            <form class="col-md-6">
              <div class="form-group" style="margin-bottom: 10px">
                <label class="input_header_style" for="lm_message">{{
                  this.lang.popup_card1[1]
                }}</label>
                <input
                  type="text"
                  @input="checkMessageCharacterLimit()"
                  class="form-control message_input_form"
                  id="msg"
                  :placeholder="this.lang.popup_card1[2]"
                  v-model="lm_message"
                  required
                />
                <p
                  :class="{
                    'lm-counter': !isMacbook(),
                    'lm-counter-mac': isMacbook(),
                  }"
                >
                  {{ lm_message_length }}/192
                </p>
              </div>
              <div class="form-group">
                <label class="input_header_style" for="lm_contact">{{
                  this.lang.popup_card1[3]
                }}</label>
                <input
                  type="text"
                  @focus="addPhoneCode()"
                  @input="checkContactCharacterLimit()"
                  class="form-control contact_input_form"
                  id="contact"
                  :placeholder="this.lang.popup_card1[4]"
                  v-model="lm_contact"
                  required
                />
                <p
                  :class="{
                    'lm-counter': !isMacbook(),
                    'lm-counter-mac': isMacbook(),
                  }"
                >
                  {{ lm_contact_length }}/24
                </p>
              </div>
            </form>
            <div v-if="!isMacbook()">
              <form class="col-md-6">
                <div class="form-group">
                  <label class="input_header_style" for="lm_footnote">{{
                    this.lang.popup_card1[5]
                  }}</label>
                  <input
                    type="text"
                    @input="checkFootnoteCharacterLimit()"
                    class="form-control message_input_form"
                    id="footnote"
                    :placeholder="this.lang.popup_card1[6]"
                    v-model="lm_footnote"
                    required
                  />
                  <p class="lm-counter">{{ lm_footnote_length }}/36</p>
                </div>
              </form>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 10px; margin-bottom: -20px"
          >
            <button
              type="submit"
              class="cancel_btn"
              style="margin-right: 50px"
              @click="backManage"
            >
              {{ this.lang.popup_card1[7] }}
            </button>
            <button
              type="submit"
              class="enable_btn"
              @click="lostModeConfirmation"
            >
              {{ this.lang.popup_card1[8] }}
            </button>
            <div v-if="nochanges" class="warning-msg text-center">
              {{ this.lang.popup_card1[9] }}
            </div>
          </div>
        </div>
      </div>
      <!--Edit Date Form-->
      <div class="lostmode-card-change-date">
        <div class="row">
          <div class="lostmode-text">
            {{ this.lang.popup_card_change_date[4] }}
          </div>
          <div class="lostmode-content">
            <div style="padding: 10px">
              <label class="input_header_style" for="lm_message">{{
                this.lang.popup_card_change_date[0]
              }}</label>
              <input
                type="date"
                class="form-control message_input_form"
                id="msg"
                :placeholder="this.lang.popup_card_change_date[1]"
                v-model="formData.date_renew"
                required
              />
            </div>
            <div
              style="text-align: center; margin-left: 20px; margin-top: 30px"
            >
              <button
                type="submit"
                class="cancel_btn"
                style="margin-right: 50px"
                @click="onClosePopupToChangeDue"
              >
                {{ this.lang.popup_card_change_date[2] }}
              </button>
              <button
                type="submit"
                class="enable_btn"
                style="margin-top: 20px; margin-right: 50px"
                @click="onEditPaymentDueDate"
              >
                {{ this.lang.popup_card_change_date[3] }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Lost Mode Confirmation-->
      <div class="loading-card" v-if="lostmodeenabled">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/warning.png" class="img_style" />
            <p class="notiftext_title">{{ this.lang.popup_card2[0] }}</p>
            <p class="notiftext">{{ this.lang.popup_card2[1] }}</p>
            <button
              type="submit"
              class="mandev_btn text-center"
              @click="goLostMode"
            >
              {{ this.lang.popup_card2[2] }}
            </button>
            <button
              type="submit"
              class="dashboard_btn text-center"
              @click="backManage"
            >
              {{ this.lang.popup_card2[3] }}
            </button>
          </div>
        </div>
      </div>
      <!--Remove Profile Confirmation-->
      <div class="loading-card" v-if="removeprofile">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/warning.png" class="img_style" />
            <p class="notiftext_title">{{ this.lang.popup_card3[0] }}</p>
            <p class="notiftext">{{ this.lang.popup_card3[1] }}</p>
            <button
              type="submit"
              class="mandev_btn text-center"
              @click="removeProfile"
            >
              {{ this.lang.popup_card3[2] }}
            </button>
            <button
              type="submit"
              class="dashboard_btn text-center"
              @click="onCancelRemoveProfilePopup"
            >
              {{ this.lang.popup_card3[3] }}
            </button>
          </div>
        </div>
      </div>
      <!--Remove Device Confirmation-->
      <div class="loading-card" v-if="removedevice">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/warning.png" class="img_style" />
            <p class="notiftext_title">
              {{ this.lang.popup_card_remove_device[0] }}
            </p>
            <p class="notiftext">{{ this.lang.popup_card_remove_device[1] }}</p>
            <button
              type="submit"
              class="mandev_btn text-center"
              @click="removeDevice"
            >
              {{ this.lang.popup_card_remove_device[2] }}
            </button>
            <button
              type="submit"
              class="dashboard_btn text-center"
              @click="onCancelRemoveDevicePopup"
            >
              {{ this.lang.popup_card_remove_device[3] }}
            </button>
          </div>
        </div>
      </div>
      <!--Loading Command-->
      <div class="loading-card" v-if="loadingCommand">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/loading.gif" class="img_style" />
            <p class="notiftext_title">{{ this.lang.popup_card_loading[1] }}</p>
          </div>
        </div>
      </div>
      <!--Sucess command-->
      <div class="loading-card" v-if="success_add">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/check.png" class="img_style" />
            <p class="notiftext_title" style="margin-left: 10px">
              {{ this.lang.popup_card_success[0] }}
            </p>
            <p class="notiftext">{{ this.lang.popup_card_success[1] }}</p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 20px"
              @click="onSuccessReturnToHomepage"
            >
              {{ this.lang.popup_card_success[2] }}
            </button>
          </div>
        </div>
      </div>
      <!--Sucess command for removal-->
      <div class="loading-card" v-if="success_add_command">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/check.png" class="img_style" />
            <p class="notiftext_title" style="margin-left: 10px">
              {{ this.lang.popup_card_success_command[0] }}
            </p>
            <p class="notiftext">
              {{ this.lang.popup_card_success_command[1] }}
            </p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 20px"
              @click="onSuccessReturnToDeviceList"
            >
              {{ this.lang.popup_card_success_command[2] }}
            </button>
          </div>
        </div>
      </div>
      <!--Fail command-->
      <div class="loading-card" v-if="error_add">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/multiply.png" class="img_style" />
            <p class="notiftext_title">{{ this.lang.popup_card_fail[0] }}</p>
            <p class="notiftext">{{ this.lang.popup_card_fail[1] }}</p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 20px"
              @click="onFailReturnToHomepage"
            >
              {{ this.lang.popup_card_fail[2] }}
            </button>
          </div>
        </div>
      </div>
      <!--Fail command removal-->
      <div class="loading-card" v-if="fail_add_command">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/multiply.png" class="img_style" />
            <p class="notiftext_title">
              {{ this.lang.popup_card_fail_command[0] }}
            </p>
            <p class="notiftext">{{ this.lang.popup_card_fail_command[1] }}</p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 20px"
              @click="onFailReturnToHomepage"
            >
              {{ this.lang.popup_card_fail_command[2] }}
            </button>
          </div>
        </div>
      </div>
      <!--Access denied-->
      <div class="loading-card" v-if="page_unavailable">
        <div class="loading-content">
          <div class="loading-text">
            <img
              src="../assets/multiply.png"
              class="img_style_denied_perms"
              style="margin-left: 15px"
            />
            <p class="notiftext_title_denied_perms" style="margin-left: 20px">
              {{ this.lang.popup_card_access_denied[0] }}
            </p>
            <p class="notiftext_denied_perms">
              {{ this.lang.popup_card_access_denied[1] }}
            </p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-left: 15px; margin-top: 20px"
              @click="goBack"
            >
              {{ this.lang.popup_card_access_denied[2] }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
  </div>
</template>

<script>
import SideNavbar from "./SideNavbar.vue";
import BackButton from "./BackButton.vue";
import BottomFooter from "./BottomFooter.vue";

export default {
  components: {
    SideNavbar,
    BackButton,
    BottomFooter,
  },
  data() {
    return {
      lm_message: "",
      lm_message_length: 0,
      lm_contact: "",
      lm_contact_length: 0,
      lm_footnote: "",
      lm_footnote_length: 0,
      modelname: localStorage.selectedmodel,
      overlayup: false,
      lostmodeload: false,
      lostmodeenabled: false,
      nochanges: false,
      success: false,
      removeprofile: false,
      removedevice: false,
      loadingCommand: false,
      success_add: false,
      fail_add: false,
      success_add_command: false,
      fail_add_command: false,
      page_unavailable: false,
      is_lost_triggered: false,
      names: localStorage.selectedname,
      storage: localStorage.selectedstorage,
      selected: localStorage.selected,
      msisdns: localStorage.selectedms,
      contacts: localStorage.selectedcontact,
      contract_status: localStorage.contractStatus,
      last_trigger: localStorage.selectedLastTrigger,
      select_type: localStorage.selectedDeviceType,
      formData: {
        date_renew: "",
        temp_serial_number: "",
      },
      lang: {
        title: "",
        second_title: "",
        content: "",
        details: [],
        popup_card1: [],
        popup_card2: [],
        popup_card3: [],
        popup_card4: [],
        popup_card5: [],
        popup_card6: [],
        popup_card7: [],
        popup_card8: [],
        popup_card9: [],
        popup_card_change_date: [],
        popup_card_remove_device: [],
        popup_card_success: [],
        popup_card_fail: [],
        popup_card_loading: [],
        popup_card_success_command: [],
        popup_card_fail_command: [],
        popup_card_access_denied: [],
        enable_button: "",
        disable_button: "",
        ring_button: "",
        locate_button: "",
        remove_button: "",
        remove_profile: "",
      },
      intervalId: null,
    };
  },
  mounted() {
    // Set up interval to periodically request lock status
    this.intervalId = setInterval(() => {
      this.checkLostModeStatus();
    }, 9 * 1000); // Check every 9 seconds
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    goBack() {
      this.$router.replace("/device-list"); // Go back one step in the history
    },
    goDashboard() {
      this.$router.replace("/dashboard");
    },
    enableLostMode() {
      this.lostmodeload = true;
      this.overlayup = true;
    },
    addPhoneCode() {
      if (!this.lm_contact.startsWith("60")) {
        this.lm_contact = "60 " + this.lm_contact;
      }
    },
    checkMessageCharacterLimit() {
      if (this.lm_message.length > 192) {
        this.lm_message = this.lm_message.substring(0, 192);
      }
      this.lm_message_length = this.lm_message.length;
    },
    checkContactCharacterLimit() {
      if (this.lm_contact.length > 24) {
        this.lm_contact = this.lm_contact.substring(0, 24);
      }
      this.lm_contact_length = this.lm_contact.length;
    },
    checkFootnoteCharacterLimit() {
      if (this.lm_footnote.length > 36) {
        this.lm_footnote = this.lm_footnote.substring(0, 36);
      }
      this.lm_footnote_length = this.lm_footnote.length;
    },
    backManage() {
      this.lostmodeload = false;
      this.lostmodeenabled = false;
      this.overlayup = false;
      this.nochanges = false;
      this.removedevice = false;
    },
    lostModeConfirmation() {
      if (
        this.lm_message == "" &&
        this.lm_footnote == "" &&
        this.lm_contact == ""
      ) {
        this.nochanges = true;
      } else {
        this.lostmodeload = false;
        this.lostmodeenabled = true;
      }
    },
    removeConfirmation() {
      this.removedevice = true;
      this.overlayup = true;
    },
    removeProfile() {
      this.removeprofile = false;
      this.loadingCommand = true;

      const axios = require("axios");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v1/dep/deroll_devices",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          token: localStorage.token,
          serial_number: this.selected,
          is_enterprise: this.select_type,
        }),
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.status == 200) {
            this.success_add_command = true;
          } else {
            this.fail_add_command = true;
            console.log("LOL FRFR");
          }
        })
        .catch((error) => {
          this.fail_add_command = true;
          console.log(error);
        });

      this.loadingCommand = false;
    },
    removeDevice() {
      this.removedevice = false;
      this.loadingCommand = true;

      const axios = require("axios");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v1/dep/disown_devices",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          token: localStorage.token,
          serial_number: this.selected,
          is_enterprise: this.select_type,
        }),
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.status == 200) {
            this.success_add_command = true;
          } else {
            this.fail_add_command = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.fail_add_command = true;
        });

      this.loadingCommand = false;
    },
    onFailReturnToHomepage() {
      this.overlayup = false;
      this.error_add = false;
      window.location.reload();
    },
    onSuccessReturnToHomepage() {
      this.overlayup = false;
      this.success_add = false;
      window.location.reload();
    },
    onSuccessReturnToDeviceList() {
      this.overlayup = false;
      this.success_add = false;
      this.$router.replace("/device-list");
    },
    checkLostModeStatus() {
      const axios = require("axios");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL +
          "/v1/mdm/lost_mode/check-device-status",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          session_id: localStorage.token,
          serial_number: localStorage.selected,
          device_type_indicator: localStorage.selectedDeviceType,
        }),
      };

      axios
        .request(config)
        .then((response) => {
          if (response.status == 200 && response.data["locked"] == true) {
            window.location.href = "/device-lost-mode";
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    },
    goLostMode() {
      //this.$router.replace('/device-lost-mode');
      const axios = require("axios");
      if (!this.is_lost_triggered) {
        this.is_lost_triggered = true;
      } else {
        return;
      }
      let config = null;
      let data = null;

      if (localStorage.selectedDeviceType == "consumer") {
        data = JSON.stringify({
          lost_mode_message: this.lm_message,
          lost_mode_phone_number: this.lm_contact,
          device_type_indicator: this.select_type,
          serial_number: localStorage.selected,
          session_id: localStorage.token,
          footnote: this.lm_footnote,
        });
      } else if (localStorage.selectedDeviceType == "enterprise") {
        data = JSON.stringify({
          lost_mode_message: this.lm_message,
          lost_mode_phone_number: this.lm_contact,
          device_type_indicator: this.select_type,
          serial_number: localStorage.selected,
          session_id: localStorage.token,
          footnote: this.lm_footnote,
        });
      }

      config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v1/mdm/lost_mode/enable",
        headers: {
          "Content-Type": "application/json", // Set Content-Type for JSON data
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          this.$router.replace("/device-lost-mode");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onOpenPopupToChangeDue(phone_serial_number) {
      this.overlayup = true;
      this.formData.temp_serial_number = phone_serial_number;
      var lostmodeContent = document.querySelector(
        ".lostmode-card-change-date"
      );
      if (lostmodeContent) {
        lostmodeContent.style.display = "flex";
      } else {
        console.error(
          "Element with class 'lostmode-card-change-date' not found."
        );
      }
    },
    onClosePopupToChangeDue() {
      this.overlayup = false;
      var lostmodeContent = document.querySelector(
        ".lostmode-card-change-date"
      );
      if (lostmodeContent) {
        lostmodeContent.style.display = "none";
      } else {
        console.error(
          "Element with class 'lostmode-card-change-date' not found."
        );
      }
    },
    onEditPaymentDueDate() {
      var lostmodeContent = document.querySelector(
        ".lostmode-card-change-date"
      );
      if (lostmodeContent) {
        lostmodeContent.style.display = "none";
      } else {
        console.error(
          "Element with class 'lostmode-card-change-date' not found."
        );
      }
      this.loadingCommand = true;
      const axios = require("axios");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL + "/v1/dashboard/device/renew_date",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          token: localStorage.token,
          device_serial_number: this.formData.temp_serial_number,
          new_date: this.formData.date_renew,
        }),
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          if (response.status === 200) {
            this.loadingCommand = false;
            this.updateDeviceDetails();
            this.success_add = true;
          } else {
            this.loadingCommand = false;
            this.error_add = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingCommand = false;
          this.error_add = true;
        });
    },
    updateDeviceDetails() {
      const axios = require("axios");

      let data = JSON.stringify({
        token: localStorage.token,
        MSISDN: localStorage.selectedms,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v1/dashboard/MSISDN_lookup",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          if (response.status === 200) {
            const devices_data = response.data["200"][0];
            console.log(devices_data);

            //this.modelname = devices_data[1];
            //this.selected = devices_data[7];
            //this.msisdns = devices_data[11];
            this.contacts = devices_data[16];
            //this.storage = devices_data[2];
            //this.contract_status = devices_data[17];

            //localStorage.selectedname = this.modelname;
            //localStorage.selectedstorage = this.storage;
            //localStorage.selected = this.selected;
            //localStorage.selectedms = this.msisdns;
            localStorage.selectedcontact = this.contacts;
          } else {
            this.error_add = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingCommand = false;
          this.error_add = true;
        });
    },
    async langchange(picker) {
      console.log(picker);
      console.log(this.data);
      const response = await fetch("/devicedetails.json");
      const data = await response.json();
      this.data = data;
      this.lang = this.data[picker];
      if (this.isMacbook() == true) {
        this.lang.enable_button = "Lock Device";
        this.lang.popup_card1[0] = "Lock Mode Activation";
        this.lang.popup_card1[2] = "Enter lock mode message for this device";
        this.lang.popup_card1[4] = "Enter reachable contact in the lock mode";
        (this.lang.popup_card1[8] = "Enable Lock Mode"),
          (this.lang.popup_card2[0] = "Activate Lock Mode?");
        this.lang.popup_card2[1] =
          "Enabling lock mode will send customer’s device into lock mode when the device is connected to the Internet.";
      }
      this.$refs.BottomFooterRef.langchange(picker);
    },
    onRemoveProfile() {
      this.removeprofile = true;
      this.overlayup = true;
    },
    onRemoveDevice() {
      this.removedevice = true;
      this.overlayup = true;
    },
    onCancelRemoveProfilePopup() {
      this.removeprofile = false;
      this.overlayup = false;
    },
    onCancelRemoveDevicePopup() {
      this.removedevice = false;
      this.overlayup = false;
    },
    isiPad() {
      if (this.modelname.toLowerCase().includes("ipad")) {
        return true;
      } else {
        return false;
      }
    },
    isMacbook() {
      if (this.modelname.toLowerCase().includes("mac")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.layout {
  display: flex;
}

.regdevice_contents {
  flex: 1;
  padding-left: 16px;
  font-family: "Century Gothic";
}

.regdevice_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 24px;
  margin-top: 5px;
}

.regdevice_secondary_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 16px;
}

.regdevice_secondary_container {
  margin-top: 15px;
}

.regdevice_container {
  display: flex;
  max-height: 680px;
  justify-content: center;
  overflow-y: auto;
}

/*Card CSS style*/
.card {
  width: 950px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card_content {
  padding: 10px;
}

.device_info {
  display: flex;
}

.device_image {
  width: 180px;
  height: 180px;
  margin-right: 20px;
}

.card_devicename_text {
  font-family: "Century Gothic Bold";
  font-size: 18px;
  margin-bottom: 10px;
}

.card_details_strong {
  font-family: "Century Gothic Bold";
  font-size: 14px;
  margin-bottom: 2px;
}

.card_details_text {
  font-family: "Century Gothic";
  font-size: 14px;
  margin-bottom: 2px;
}

.button_container {
  display: flex;
  height: 55px;
  padding-left: 210px;
  background-color: #fff6ce;
}

.lostmode_btn {
  margin-top: 10px;
  width: 110px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.lostmode_btn:hover {
  filter: brightness(90%);
}

.removebtn {
  padding-left: 370px;
}

.removedevice_btn {
  text-align: center;
  margin-top: 10px;
  width: 110px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  background-color: #fc2929;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.removedevice_btn:hover {
  filter: brightness(90%);
}

.removeprofile_btn {
  text-align: center;
  margin-top: 10px;
  width: 110px;
  height: 35px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  margin-right: 10px;
  background-color: #000;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.removeprofile_btn:hover {
  filter: brightness(90%);
}

/*Lost Mode Form Style*/
.input_header_style {
  font-family: "Century Gothic Bold";
  font-size: 12px;
  color: #013042;
  margin-bottom: 2px;
}

.lm-counter {
  text-align: right;
  font-size: small;
  padding-right: 30px;
}

.lm-counter-mac {
  text-align: right;
  font-size: small;
  padding-left: 300px;
}

.message_input_form {
  vertical-align: top !important;
  text-align: left !important;
  background-color: #ffffff;
  height: 100px;
  width: 350px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}

.message_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}

.contact_input_form {
  background-color: #ffffff;
  height: 35px;
  width: 350px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}

.contact_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}

::placeholder {
  font-size: 12px;
}

.enable_btn {
  text-align: center;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.enable_btn:hover {
  filter: brightness(90%);
}

.cancel_btn {
  text-align: center;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffffff;
  border-color: #013042;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.cancel_btn:hover {
  filter: brightness(90%);
}

/*Lost Mode Enable Form*/
.lostmode-card {
  position: fixed;
  width: 800px;
  height: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
}

.loastmode-card-macbook {
  position: fixed;
  width: 400px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
}

.lostmode-card-change-date {
  position: absolute;
  width: 600px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: none;
}

.lostmode-content {
  display: flex;
  padding-left: 20px;
}

.lostmode-text {
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: -10px;
}

/*Lost mode confirmation style */
.img_style {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.notiftext_title {
  font-family: "Century Gothic Bold";
  font-size: 12px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext {
  font-family: "Century Gothic";
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.mandev_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.mandev_btn:hover {
  filter: brightness(90%);
}

.change_due_date_btn {
  text-align: center;
  width: 100px;
  height: 25px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  margin-left: 10px;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.change_due_date_btn:hover {
  filter: brightness(90%);
}

.dashboard_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffffff;
  border-color: #013042;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.dashboard_btn:hover {
  filter: brightness(90%);
}

.loading-card {
  position: fixed;
  width: 280px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  font-family: CenturyGothic, sans-serif;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 9998;
  /* Below the loading content */
}

.warning-msg {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.notiftext_title_denied_perms {
  font-family: "Century Gothic Bold";
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.img_style_denied_perms {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  left: 10px;
}
</style>
