<template>
  <div class="layout">
    <div class="overlay" v-if="loading"></div>
    <div style="z-index: 100 !important">
      <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="regdevice_contents">
      <BackButton @click="goBack" :message="this.lang.back_button"></BackButton>
      <div class="regdevice_header">
        {{ this.lang.title }}
      </div>
      <div class="dashboard_role">Current Role : {{ this.currentRole }}</div>
      <div class="regdevice_secondary_container">
        <p class="regdevice_secondary_header">{{ this.lang.second_title }}</p>
        <p class="contents_header_text">{{ this.lang.content }}</p>
      </div>
      <div class="text-center" v-if="isEnterpriseAvailable === true">
        <div
          class="form-selection-type"
          style="margin-bottom: 5px; justify-items: center"
        >
          <label class="input_header_style" for="fullname">{{
            this.lang.company_selection
          }}</label>
          <select
            type="text"
            class="options-selector form-control"
            id="fullname"
            :placeholder="this.lang.fullname_hint"
            v-model="register_option"
            required
          >
            <option value="1">Consumer</option>
            <option value="2">Enterprise</option>
          </select>
        </div>
      </div>
      <div class="regdevice_container">
        <div v-if="register_option == '1'">
          <form class="col-md-6 d-flex flex-column w-100" @submit.prevent="register">
            <div class="d-flex flex-row">
              <div class="mx-3">
                <div>
                  <label class="input_header_style" for="mac_selector"
                    >{{ this.lang.selector_hint }}:</label
                  >
                  <select
                    type="select-category"
                    @change="handleSelectionOfDue"
                    class="form-control custom_input_form"
                    id="mac_selector"
                    v-model="is_not_phone"
                    required
                  >
                    <!--<option value="before-due">Before Due</option>-->
                    <option value="2">iPad</option>
                    <option value="1">Macbook</option>
                    <option value="0">iPhone</option>
                  </select>
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="fullname">{{
                    this.lang.fullname
                  }}</label>
                  <input
                    type="text"
                    class="form-control custom_input_form"
                    id="fullname"
                    :placeholder="this.lang.fullname_hint"
                    v-model="fullname"
                    required
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="msisdn">{{
                    this.lang.msisdn
                  }}</label>
                  <input
                    @focus="addPhoneCode"
                    type="tel"
                    class="form-control custom_input_form"
                    id="msisdn"
                    :placeholder="this.lang.msisdn_hint"
                    v-model="msisdn"
                    required
                    pattern="[0-9]{11,12}"
                    title="Please input your MSISDN number ranging from 11-12 digits only" 
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="serialnum">{{
                    this.lang.serialnum
                  }}</label>
                  <input
                    type="text"
                    class="form-control custom_input_form"
                    id="serialnum"
                    :placeholder="this.lang.serialnum_hint"
                    style="text-transform: uppercase"
                    v-model="serialnum"
                    required
                    pattern="[A-Z0-9]{8,14}" 
                    title="Please input a valid serial number" 
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="devicemodel">{{
                    this.lang.devicemodel
                  }}</label>
                  <input
                    type="text"
                    class="form-control custom_input_form"
                    id="devicemodel"
                    :placeholder="this.lang.devicemodel_hint"
                    v-model="devicemodel"
                    required
                  />
                </div>
              </div>

              <div class="mx-3">
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="nric">{{
                    this.lang.nric
                  }}</label>
                  <input
                    type="text"
                    class="form-control custom_input_form"
                    id="nric"
                    :placeholder="this.lang.nric_hint"
                    v-model="nric"
                    required
                    pattern="^[0-9]{12}$|^[A-Za-z0-9]+$" 
                    title="Please input a valid NRIC / Passport" 
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="email">{{
                    this.lang.email
                  }}</label>
                  <input
                    type="email"
                    class="form-control custom_input_form"
                    id="email"
                    :placeholder="this.lang.email_hint"
                    v-model="email"
                    required
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="imei1">{{
                    this.lang.imei1
                  }}</label>
                  <input
                    type="text"
                    class="form-control custom_input_form"
                    id="imei1"
                    :placeholder="this.lang.imei1_hint"
                    v-model="imei1"
                    :disabled="is_not_phone === '1' || is_not_phone === '2'"
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="imei2">{{
                    this.lang.imei2
                  }}</label>
                  <input
                    type="text"
                    class="form-control custom_input_form"
                    id="imei2"
                    :placeholder="this.lang.imei2_hint"
                    v-model="imei2"
                    :disabled="is_not_phone === '1' || is_not_phone === '2'"
                  />
                </div>
                <div class="form-group" style="margin-bottom: 5px">
                  <label class="input_header_style" for="contracttenure">{{
                    this.lang.contracttenure
                  }}</label>
                  <input
                    type="tel"
                    class="form-control custom_input_form"
                    id="contracttenure"
                    :placeholder="this.lang.contracttennue_hint"
                    v-model="contracttenure"
                    required
                    pattern="[0-9]{1,2}"
                    title="Please input a valid amount for contract tenure (months)"
                  />
                </div>
              </div>
            </div>

            <div class="text-center button_container d-flex justify-content-center ">
              <button type="submit" class="reg_btn">
                {{ this.lang.register_button }}
              </button>
              <div v-if="tryagainWarning" class="warning-msg text-center">
                {{ this.lang.tryagain }}
              </div>
            </div>
          </form>
        </div>
        <div class="row" v-if="register_option == '2'">
          <form class="col-md-6">
            <div class="form-group" style="margin-bottom: 5px">
              <label class="input_header_style" for="ctrlytics_company_name">{{
                this.lang.corpRegister_company_name
              }}</label>
              <input
                type="text"
                class="form-control custom_input_form"
                id="ctrlytics_company_name"
                :placeholder="this.lang.corpRegister_company_name"
                v-model="corpRegister.company_name"
                required
              />
            </div>
            <div class="form-group" style="margin-bottom: 5px">
              <label
                class="input_header_style"
                for="ctrlytics_company_rep_email"
                >{{ this.lang.corpRegister_pic_email }}</label
              >
              <input
                @focus="addPhoneCode"
                type="text"
                class="form-control custom_input_form"
                id="ctrlytics_company_rep_email"
                :placeholder="this.lang.corpRegister_pic_email"
                v-model="corpRegister.pic_email"
                required
              />
            </div>
          </form>
          <form class="col-md-6">
            <div class="form-group" style="margin-bottom: 5px">
              <label
                class="input_header_style"
                for="ctrlytics_company_rep_name"
                >{{ this.lang.corpRegister_pic_name }}</label
              >
              <input
                type="text"
                class="form-control custom_input_form"
                id="ctrlytics_company_rep_name"
                :placeholder="this.lang.corpRegister_pic_name"
                v-model="corpRegister.pic_name"
                required
              />
            </div>
            <div class="form-group" style="margin-bottom: 5px">
              <label
                class="input_header_style"
                for="ctrlytics_company_rep_contact"
                >{{ this.lang.corpRegister_pic_contact }}</label
              >
              <input
                @focus="addPhoneCode"
                type="text"
                class="form-control custom_input_form"
                id="ctrlytics_company_rep_contact"
                :placeholder="this.lang.corpRegister_pic_contact"
                v-model="corpRegister.pic_contact"
                required
              />
            </div>
          </form>
          <div></div>
        </div>
        <div class="table_container" v-if="register_option == '2'">
          <table class="table_styles">
            <thead class="table_header">
              <tr>
                <th class="tablehead_text">
                  {{ this.lang.corpRegister_table_input_header[0] }}
                </th>
                <th class="tablehead_text">
                  {{ this.lang.corpRegister_table_input_header[1] }}
                </th>
                <th class="tablehead_text">
                  {{ this.lang.corpRegister_table_input_header[2] }}
                </th>
                <th class="tablehead_text">
                  {{ this.lang.corpRegister_table_input_header[3] }}
                </th>
                <th class="tablehead_text">
                  {{ this.lang.corpRegister_table_input_header[4] }}
                </th>
                <th class="tablehead_text">
                  {{ this.lang.corpRegister_table_input_header[5] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(input_data, index) in device_corp_data"
                :key="index"
                :class="{ odd_row: index % 2 === 0, even_row: index % 2 !== 0 }"
                class="table_body_border"
              >
                <td class="tablecolumn_text">{{ index + 1 }}</td>
                <td class="tablecolumn_text">
                  {{ input_data["device_name"] }}
                </td>
                <td class="tablecolumn_text">
                  {{ input_data["serial_number"] }}
                </td>
                <td class="tablecolumn_text">{{ input_data["status"] }}</td>
                <td class="tablecolumn_text">{{ input_data["tenure"] }}</td>
                <td class="tablecolumn_text">
                  <button
                    type="submit"
                    class="x_button"
                    @click="removeDevice(index)"
                  >
                    {{ this.lang.register_x_mark }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <div
            class="button_container"
            style="display: flex; margin-left: 32%"
            v-if="register_option == '2'"
          >
            <div class="upload_proof_btn" style="padding-right: 20px">
              <input
                type="file"
                id="browse_file_input"
                accept=".csv"
                v-on:change="uploadCSV($event)"
                style="width: 0px; display: none"
              />
              <button
                type="button"
                id="browser"
                class="upload_btn"
                onclick="browse_file_input.click()"
              >
                {{ this.lang.upload_batch_csv }}
              </button>
            </div>
            <div v-if="tryagainWarning" class="warning-msg text-center">
              {{ this.lang.tryagain }}
            </div>
            <div class="button_container" v-if="register_option == '2'">
              <button
                type="submit"
                class="add_btn"
                @click="retrieveAddDevicePopup()"
              >
                {{ this.lang.add_device }}
              </button>
            </div>
          </div>
          <div class="button_container" v-if="register_option == '2'">
            <button type="submit" class="reg_btn" @click="registerEnterprise()">
              {{ this.lang.register_button }}
            </button>
          </div>
        </div>
      </div>

      <div class="loading-card" v-if="success">
        <div class="loading-content">
          <div class="loading-text">
            <p class="notiftext_title">{{ this.lang.notice_title }}</p>
            <p class="notiftext">{{ this.lang.notice_text }}</p>
            <img src="../assets/check.png" class="img_style" />
            <div style="margin-top: 5px">
              <button
                type="submit"
                class="mandev_btn text-center"
                @click="goManageDevice"
              >
                {{ this.lang.notice_button1 }}
              </button>
              <button
                style="margin-left: 10px"
                type="submit"
                class="dashboard_btn text-center"
                @click="goDashboard"
              >
                {{ this.lang.notice_button2 }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="loading-card" v-if="tryagainWarning">
        <div class="loading-content">
          <div class="loading-text">
            <p class="notiftext_title">{{ this.lang.error_prompt_title }}</p>
            <p class="notiftext">{{ this.lang.error_prompt_description }}</p>
            <img src="../assets/multiply.png" class="img_style" />
            <div style="margin-top: 5px">
              <button
                style="margin-left: 10px"
                type="submit"
                class="dashboard_btn text-center"
                @click="backToRegister"
              >
                {{ this.lang.error_prompt_button1 }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Temporary prompt to load the csv-->
      <div class="loading-card" v-if="loadingCommand">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/loading.gif" class="img_style" />
            <p class="notiftext_title">Loading CSV File...</p>
            <!--<p class="notiftext">{{ "Serial Numbers " + this.temp_csv_upload_status}}</p>-->
          </div>
        </div>
      </div>
      <!--Ctrlytics for Mac not available-->
      <!---<div class="loading-card" v-if="tryagainWarning">
                <div class="loading-content">
                    <div class="loading-text">
                        <p class="notiftext_title">{{ this.lang.feature_unavailable_macos[0] }}</p>
                        <p class="notiftext">{{ this.lang.feature_unavailable_macos[1] }}</p>
                        <img src="../assets/multiply.png" class="img_style">
                        <div style="margin-top: 5px;">
                            <button style="margin-left: 10px;" type="submit" class="dashboard_btn text-center"
                                @click="backToRegister">{{ this.lang.feature_unavailable_macos[2] }}</button>
                        </div>
                    </div>
                </div>
            </div>-->

      <div
        class="loading-card"
        v-if="tryagainWarning2 && productFamily != null"
      >
        <div class="loading-content">
          <div class="loading-text">
            <div v-if="productFamily == 'Mac'">
              <p class="notiftext_title">
                {{ this.lang.feature_unavailable_macos[0] }}
              </p>
              <p class="notiftext">{{ this.lang.register_api_error_msg }}</p>
              <img src="../assets/multiply.png" class="img_style" />
              <div style="margin-top: 5px">
                <button
                  style="margin-left: 10px"
                  type="submit"
                  class="dashboard_btn text-center"
                  @click="backToRegister"
                >
                  {{ this.lang.feature_unavailable_macos[2] }}
                </button>
              </div>
            </div>
            <div v-if="productFamily == 'iPad'">
              <p class="notiftext_title">
                {{ this.lang.feature_unavailable_ipados[0] }}
              </p>
              <p class="notiftext">{{ this.lang.register_api_error_msg }}</p>
              <img src="../assets/multiply.png" class="img_style" />
              <div style="margin-top: 5px">
                <button
                  style="margin-left: 10px"
                  type="submit"
                  class="dashboard_btn text-center"
                  @click="backToRegister"
                >
                  {{ this.lang.feature_unavailable_ipados[2] }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Add-device-->
      <div class="loading-card" v-if="add_device_window">
        <div class="loading-content">
          <div class="text-center">
            <div class="form-group" style="margin-bottom: 5px">
              <label class="input_header_style" for="fullname">{{
                this.lang.add_device_popup[0]
              }}</label>
              <input
                type="text"
                class="form-control custom_input_form"
                id="fullname"
                :placeholder="this.lang.serialnum_hint"
                v-model="temp_serial_number_add"
                required
              />
              <button
                style="margin-left: 10px; margin-top: 10px; margin-bottom: 10px"
                type="submit"
                class="reg_btn text-center"
                @click="checkDevice"
              >
                {{ this.lang.add_device_popup[1] }}
              </button>
              <p class="input_header_style" for="fullname">
                {{ this.lang.add_device_popup[2] }}:
              </p>
              <p>{{ this.temp_device_status_add }}</p>
            </div>
            <div class="form-group" style="margin-bottom: 5px">
              <button
                style="margin-left: 10px"
                :disabled="this.device_available_to_add"
                type="submit"
                class="reg_btn text-center"
                @click="addDevice"
              >
                {{ this.lang.add_device_popup[3] }}
              </button>
            </div>
            <div class="form-group" style="margin-bottom: 5px">
              <button
                type="submit"
                class="cancel_btn"
                @click="closeAddDevicePopup()"
              >
                {{ this.lang.cancel_btn }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Remove-device-popup-->
      <div class="loading-card" v-if="remove_device_prompt">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/warning.png" class="img_style_denied_perms" />
            <p class="notiftext_title">
              {{ this.lang.remove_device_popup[0] }}
            </p>
            <div style="margin-top: 15px">
              <button
                style="margin-left: 10px"
                type="submit"
                class="dashboard_btn text-center"
                @click="backToRegister"
              >
                {{ this.lang.remove_device_popup[1] }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--CSV Import fail-->
      <div class="loading-card" v-if="tryagainCSVImport">
        <div class="loading-content">
          <div class="loading-text">
            <p class="notiftext_title">{{ this.lang.invalid_csv[0] }}</p>
            <p class="notiftext">{{ this.lang.invalid_csv[1] }}</p>
            <img src="../assets/multiply.png" class="img_style" />
            <div style="margin-top: 5px">
              <button
                style="margin-left: 10px"
                type="submit"
                class="dashboard_btn text-center"
                @click="backToRegister"
              >
                {{ this.lang.invalid_csv[2] }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Ctrlytics for iPad not available-->
      <!--<div class="loading-card" v-if="tryagainWarning">
                <div class="loading-content">
                    <div class="loading-text">
                        <p class="notiftext_title">{{ this.lang.feature_unavailable_ipados[0] }}</p>
                        <p class="notiftext">{{ this.lang.feature_unavailable_ipados[1] }}</p>
                        <img src="../assets/multiply.png" class="img_style">
                        <div style="margin-top: 5px;">
                            <button style="margin-left: 10px;" type="submit" class="dashboard_btn text-center"
                                @click="backToRegister">{{ this.lang.feature_unavailable_ipados[2] }}</button>
                        </div>
                    </div>
                </div>
            </div>-->
      <!--Access denied-->
      <div class="loading-card" v-if="page_unavailable">
        <div class="loading-content">
          <div class="loading-text">
            <img src="../assets/multiply.png" class="img_style_denied_perms" />
            <p class="notiftext_title_denied_perms">
              {{ this.lang.popup_card_access_denied[0] }}
            </p>
            <p class="notiftext_denied_perms">
              {{ this.lang.popup_card_access_denied[1] }}
            </p>
            <button
              type="submit"
              class="mandev_btn text-center"
              style="margin-top: 20px"
              @click="goBack"
            >
              {{ this.lang.popup_card_access_denied[2] }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>

<script>
import SideNavbar from "./SideNavbar.vue";
import BackButton from "./BackButton.vue";
import BottomFooter from "./BottomFooter.vue";

export default {
  components: {
    SideNavbar,
    BackButton,
    BottomFooter,
  },
  data() {
    return {
      is_not_phone: "0",
      page_unavailable: false,
      fullname: "",
      serialnum: "",
      msisdn: "",
      devicemodel: "",
      email: "",
      nric: "",
      imei1: "",
      imei2: "",
      contracttenure: "",
      register_option: "1",
      temp_csv_upload_status: "0/36",
      tryagainCSVImport: false,
      loading: true,
      loadingCommand: false,
      success: false,
      tryagainWarning: false,
      tryagainWarning2: false,
      ctrlytics_ipad_err: false,
      ctrlytics_iphone_err: false,
      isEnterpriseAvailable: false,
      isFilled: false,
      device_available_to_add: true,
      add_device_window: false,
      remove_device_prompt: false,
      currentRole: localStorage.role,
      temp_serial_number_add: "",
      temp_device_name_add: "",
      temp_device_status_add: "PENDING DEVICE",
      data: [],
      device_corp_data: [],
      corpRegister: {
        company_name: "",
        pic_name: "",
        pic_email: "",
        pic_contact: "",
      },
      lang: {
        title: "",
        second_title: "",
        cancel_btn: "Cancel",
        company_selection: "",
        company_selection_hint: "",
        content: "",
        fullname: "",
        fullname_hint: "",
        serialnum: "",
        serialnum_hint: "",
        msisdn: "",
        msisdn_hint: "",
        devicemodel: "",
        devicemodel_hint: "",
        device_eligibility: false,
        email: "",
        email_hint: "",
        nric: "",
        nric_hint: "",
        imei1: "",
        imei1_hint: "",
        imei2: "",
        imei2_hint: "",
        selector_hint: "",
        contracttenure: "",
        contracttennue_hint: "",
        back_button: "",
        register_button: "",
        tryagain: "",
        notice_title: "",
        notice_text: "",
        notice_button1: "",
        notice_button2: "",
        invalid_csv: [],
        feature_unavailable_ipados: [],
        feature_unavailable_macos: [],
        csv_entry_popup: [],
        add_device_popup: [],
        remove_device_popup: [],
        load_csv_message: [],
        corpRegister_company_id: null,
        corpRegister_company_name: "",
        corpRegister_pic_email: "",
        corpRegister_pic_name: "",
        corpRegister_pic_contact: "",
        corpRegister_table_input_header: [],
        register_x_mark: "X",
        upload_batch_csv: "Upload CSV",
        add_device: "Add Device",
        remove_device: "Remove Device",
        register_api_error_msg: "",
      },
      productFamily: null,
    };
  },
  methods: {
    register() {
      if (
        this.msisdn == "" &&
        this.serialnum == "" &&
        this.email == "" &&
        this.fullname == "" &&
        this.devicemodel == "" &&
        this.email == "" &&
        this.nric == "" &&
        this.contracttenure == ""
      ) {
        this.tryagainWarning = true;
      } else {
        if (
          this.is_not_phone === "0" &&
          this.imei === "" &&
          this.imei2 === ""
        ) {
          this.tryagainWarning = true;
        }
        if (this.is_not_phone === "1" || this.is_not_phone === "2") {
          this.imei = "123456789";
          this.imei2 = "123456789";
        }
        this.loading = true;
        this.tryagainWarning = false;
        console.log("Registering");
        const axios = require("axios");

        let data = JSON.stringify({
          full_name: this.fullname,
          nric: this.nric,
          phone_no: this.msisdn,
          email: this.email,
          serial_number: this.serialnum.toUpperCase(),
          Tenure: this.contracttenure,
          Device_Model: this.devicemodel,
          imei: this.imei1,
          imei2: this.imei2,
          session_id: localStorage.token,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url:
            process.env.VUE_APP_BACKEND_URL +
            "/v1/dashboard/register/full-data",
          headers: {
            "Content-Type": "application/json", // Set Content-Type for JSON data
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log("Test response location non-error");
            console.log(response.status);
            console.log(JSON.stringify(response.data));
            if (response.data["status"] === 200) {
              this.success = true;
              this.isFilled = true;
            }
          })
          .catch((error) => {
            console.log("Test response location error");
            console.log(error);
            if (error.response.status === 403) {
              this.tryagainWarning2 = true;
              this.productFamily = error.response.data["product_family"];

              this.lang.register_api_error_msg = error.response.data["message"];
            } else {
              this.isFilled = true;
              this.tryagainWarning = true;
            }
          });
        //setTimeout(() => {
        //    this.success = true;
        //}, 3000);
      }
    },
    registerEnterprise() {
      // Fix prompt
      this.loading = true;
      this.tryagainWarning = false;

      // Get serial number
      const axios = require("axios");
      let new_data = null;
      console.log(this.corpRegister.company_name);
      console.log(this.corpRegister.pic_name);
      console.log(this.corpRegister.pic_email);
      console.log(this.corpRegister.pic_phone);
      if (this.corpRegister_company_id == null) {
        new_data = JSON.stringify({
          session_id: localStorage.token,
          company_name: this.corpRegister.company_name,
          pic_name: this.corpRegister.pic_name,
          pic_phone: this.corpRegister.pic_contact,
          pic_email: this.corpRegister.pic_email,
          device_list: this.device_corp_data,
        });
        const frozen_new_data = Object.freeze({
          session_id: localStorage.token,
          company_name: this.corpRegister.company_name,
          pic_name: this.corpRegister.pic_name,
          pic_phone: this.corpRegister.pic_contact,
          pic_email: this.corpRegister.pic_email,
          device_list: this.device_corp_data,
        });
        console.log(frozen_new_data);
      } else {
        new_data = JSON.stringify({
          session_id: localStorage.token,
          company_id: this.corpRegister_company_id,
          device_list: this.device_corp_data,
        });
        const frozen_new_data = Object.freeze({
          session_id: localStorage.token,
          company_id: this.corpRegister_company_id,
          device_list: this.device_corp_data,
        });
        console.log(frozen_new_data);
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL +
          "/v1/dashboard/register/full-data-enterprise",
        headers: {
          "Content-Type": "application/json",
        },
        data: new_data,
      };
      console.log(new_data);
      axios
        .request(config)
        .then((response) => {
          console.log(response.data[0]);
          if (response.status === 200) {
            this.success = true;
            console.log("issa 200");
          } else {
            console.log("issa not 200");
            this.tryagainWarning = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async uploadCSV(event) {
      const axios = require("axios");
      const file = event.target.files[0];
      if (file) {
        this.loading = true;
        this.loadingCommand = true;

        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await axios.post(
            process.env.VUE_APP_BACKEND_URL +
              "/v1/dashboard/register/unpack-csv",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            console.log(response.data);
            let data_entry = response.data.device_list;
            this.device_corp_data = this.device_corp_data.concat(data_entry);
            console.log(this.device_corp_data);
            this.loading = false;
            this.loadingCommand = false;
          } else {
            console.log(response.data);
            this.loading = false;
            this.loadingCommand = false;
          }
        } catch (error) {
          console.log(error);
          this.errorMessage = error.response
            ? error.response.data.error
            : error.message;
          this.tryagainCSVImport = true;
        } finally {
          this.processing = false;
        }
      }
    },
    retrieveAddDevicePopup() {
      this.loading = true;
      this.add_device_window = true;
    },
    closeAddDevicePopup() {
      // Cleanup
      this.temp_serial_number_add = "";
      this.temp_device_name_add = "";
      this.temp_device_status_add = "PENDING DEVICE";

      this.loading = false;
      this.device_available_to_add = true;
      this.add_device_window = false;
    },
    addDevice() {
      let jsonData = {
        device_name: this.temp_device_name_add,
        serial_number: this.temp_serial_number_add,
        status: this.temp_device_status_add,
      };
      this.device_corp_data.push(jsonData);

      this.temp_serial_number_add = "";
      this.temp_device_name_add = "";
      this.temp_device_status_add = "PENDING DEVICE";

      //Return to register page
      this.device_available_to_add = true;
      this.loading = false;
      this.add_device_window = false;
    },
    checkDevice() {
      // Get serial number
      const axios = require("axios");

      const data = JSON.stringify({
        session_id: localStorage.token,
        serial_number: this.temp_serial_number_add,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL +
          "/v1/dashboard/register/check-device",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // Case exceptions for already existing in the list
      // Iterate through the corpData array
      const exists = this.device_corp_data.some(
        (item) => item.serial_number === this.temp_serial_number_add
      );
      if (exists) {
        this.temp_device_status_add = "DUPLICATE ENTRY DETECTED";
        return;
      }
      // Run API Endpoint
      //Run API
      axios
        .request(config)
        .then((response) => {
          console.log(response.data[0]);
          if (response.status === 200) {
            console.log("Test");
            this.temp_device_status_add = response.data[0]["status"];
            console.log(response.data[0]);
            if (response.data[0]["status"] === "AVAILABLE") {
              this.temp_device_name_add = response.data[0]["model"];

              // Re-enable or disable
              console.log(this.temp_device_name_add);
              this.device_available_to_add = false;
            }
          } else {
            this.temp_device_status_add = "UNABLE TO CHECK";
            this.device_available_to_add = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.temp_device_status_add = "UNABLE TO RESOLVE";
        });
    },
    confirmAddDevice() {},
    removeDevice(index) {
      this.device_corp_data.splice(index, 1);
    },
    addPhoneCode() {
      if (!this.msisdn.startsWith("60")) {
        this.msisdn = "60" + this.msisdn;
      }
    },
    checkEnterpriseFeature() {
      const data = JSON.stringify({
        session_id: localStorage.token,
        specific_feature: "ctrlytics-enterprise",
      });
      const axios = require("axios");
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url:
          process.env.VUE_APP_BACKEND_URL +
          "/v1/device-details/check-aux-feature",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      //Run API
      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            if (response.data["availability"] === true) {
              this.isEnterpriseAvailable = true;
            }
          } else {
            this.isEnterpriseAvailable = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isEnterpriseAvailable = false;
        });
    },
    checkLogin() {
      //Enable overlay
      this.loading = true;
      //Prep API
      const data = JSON.stringify({
        token: localStorage.token,
      });
      const axios = require("axios");
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_BACKEND_URL + "/v1/login/validity-check",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      //Run API
      axios
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
          } else {
            this.page_unavailable = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.page_unavailable = true;
        });
    },
    goBack() {
      this.$router.replace("/dashboard"); // Go back one step in the history
    },
    goManageDevice() {
      this.$router.replace("/device-list");
    },
    goDashboard() {
      this.$router.replace("/dashboard");
    },
    backToRegister() {
      location.reload();
    },
    async langchange(picker) {
      console.log(picker);
      console.log(this.data);
      const response = await fetch("/registerdevice.json");
      const data = await response.json();
      this.data = data;
      this.lang = this.data[picker];
      this.checkEnterpriseFeature();
      this.$refs.BottomFooterRef.langchange(picker);
    },
  },
  mounted() {
    this.checkLogin();
  },
};
</script>

<style scoped>
*:disabled {
  background-color: dimgrey !important;
  color: linen;
  opacity: 1;
}

.layout {
  display: flex;
}

.regdevice_contents {
  flex: 1;
  padding-left: 16px;
  font-family: "Century Gothic";
}

.regdevice_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 24px;
  margin-top: 5px;
}

.regdevice_secondary_header {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  font-size: 20px;
  margin-top: 15px;
}

.regdevice_secondary_container {
  margin-top: 30px;
}

.regdevice_container {
  display: inline-block;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 15%;
}

.contents_header_text {
  font-size: 14px;
  margin-top: -15px;
}

.img_style {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.notiftext_title {
  font-family: "Century Gothic Bold";
  font-size: 12px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext {
  font-family: "Century Gothic";
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.input_header_style {
  font-family: "Century Gothic Bold";
  font-size: 12px;
  color: #013042;
  margin-bottom: 2px;
}

.reg_btn {
  text-align: center;
  margin-top: 20px;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.reg_btn:hover {
  filter: brightness(90%);
}

.x_button {
  text-align: center;
  width: 40px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ff0000;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.x_button:hover {
  filter: brightness(90%);
}

.upload_btn {
  text-align: center;
  margin-top: 20px;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.upload_btn:hover {
  filter: brightness(90%);
}

.add_btn {
  text-align: center;
  margin-top: 20px;
  width: 140px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.add_btn:hover {
  filter: brightness(90%);
}

.mandev_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.mandev_btn:hover {
  filter: brightness(90%);
}

.button_container {
  width: auto;
}
.cancel_btn {
  text-align: center;
  width: 150px;
  padding: 0.3vw;
  margin-left: 10px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border-radius: 28px;
  background-color: #ff0000;
  border-color: #013042;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.cancel_btn {
  filter: brightness(90%);
}

.dashboard_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 10px;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffffff;
  border-color: #013042;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.dashboard_btn:hover {
  filter: brightness(90%);
}

.custom_input_form {
  background-color: #ffffff;
  height: 35px;
  width: 400px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}

.custom_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}

.form-control:disabled {
  background-color: #adb5bd !important;
}

::placeholder {
  font-size: 12px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 9998;
  /* Below the loading content */
}

.warning-msg {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.loading-card {
  position: fixed;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  justify-items: center;
  display: grid;
  font-family: "Century Gothic", sans-serif;
  text-align: center;
}

.img_style_denied_perms {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  left: 10px;
}

.notiftext_title_denied_perms {
  font-family: "Century Gothic Bold";
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext_denied_perms {
  font-family: "Century Gothic";
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.options-selector {
  display: flex;
  width: 400px;
  left: auto;
  right: auto;
}

.table_header {
  background-color: #cccccc;
  text-align: center;
  border-bottom: 1px solid #989898;
}

.table_body_border {
  border-bottom: 1px solid #989898;
}

.tablehead_text {
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 14px;
  text-align: center;
}

.table_container {
  flex-basis: 100%;
  margin-top: 25px;
  margin-left: 10%;
  max-width: 650px;
  overflow-y: auto;
  max-height: 32vh;
}

.table_container::-webkit-scrollbar {
  width: 8px;
}

.table_container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.table_container::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px;
}

.table_styles {
  width: 100%;
}

thead tr {
  height: 50px;
}

th,
td {
  text-align: center;
  padding: 16px;
}

.odd_row {
  background-color: #ffffff;
}

.even_row {
  background-color: #e6e6e6;
}

.table_header {
  background-color: #cccccc;
  text-align: center;
  border-bottom: 1px solid #989898;
}

.table_body_border {
  border-bottom: 1px solid #989898;
}

.tablehead_text {
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 376px) {
  .custom_input_form {
    width: 280px;
  }

  .regdevice_secondary_container {
    margin-top: -10px;
  }
}
</style>
