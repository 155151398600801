<template>
<div class="layout">
    <div class="overlay" v-if="loading"></div>
    <div style="z-index: 100 !important;">
        <SideNavbar></SideNavbar>
    </div>
    <div class="regdevice_contents">
        <BackButton @click="goBack"></BackButton>
        <div class="regdevice_header">
            Manage Point-Of-Sales Accounts
        </div>
        <div class="regdevice_secondary_container">
            <p class="regdevice_secondary_header">SLA Glenmarie-01</p>
            <p class="contents_header_text">Provide changes to this account credentials.</p>
        </div>
        <div class="regdevice_container">
            <div class="row">
                <form class="col-md-6">
                    <div class="form-group">
                        <label class="input_header_style" for="newusername">New Username</label>
                        <input type="text" class="form-control custom_input_form" id="newusername" placeholder="Enter New Username" v-model="newuname" required />
                    </div>
                </form>
                <form class="col-md-6">
                    <div class="form-group">
                        <label class="input_header_style" for="newpassword">New Password</label>
                        <input type="password" class="form-control custom_input_form" id="newpassword" placeholder="Enter New Password" v-model="newpass" required />
                    </div>
                </form>
                <div class="text-center">
                    <button type="submit" class="reg_btn" @click="saveChanges">Save Changes</button>
                    <div v-if="tryagainWarning" class="warning-msg text-center">Please enter all fields</div>
                </div>
                
            </div>
        </div>
        <div class="loading-card" v-if="success">
            <div class="loading-content">
            <div class="loading-text">
                <img src="../assets/check.png" class="img_style">
                <p class="notiftext_title">Changes Saved</p>
                <p class="notiftext">You may go back to Account List and check the changes.</p>
                <button type="submit" class="mandev_btn text-center" @click="goManageAccounts">Back to Account List</button>
            </div>
            </div>
        </div>
    </div>
</div>
</template>
    
<script>
    import SideNavbar from './SideNavbar.vue';
    import BackButton from './BackButton.vue';

    export default {
        components: {
        SideNavbar,
        BackButton,
        },
        data(){
            return{
                newuname: '',
                newpass: '',
                loading: false,
                success: false,
                tryagainWarning: false,
            };
        },
        methods: {
            saveChanges(){
                if(this.newuname == '' && this.newpass == ''){
                    this.tryagainWarning = true;
                }
                else{
                    this.loading = true;
                    console.log("Registering");
                    setTimeout(() => {
                        this.success = true;
                    }, 3000);
                }
            },
            goBack() {
                this.$router.replace('/manage-accounts'); // Go back one step in the history
            },
            goManageAccounts(){
                this.$router.replace('/manage-accounts');
            },
            goDashboard(){
                this.$router.replace('/dashboard');
            }
        }
    }
</script>
    
<style scoped>
.layout{
    display: flex;
}
.regdevice_contents{
    flex: 1;
    padding-left: 16px;
    font-family: 'Century Gothic';
}
.regdevice_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
}
.regdevice_secondary_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
}
.regdevice_secondary_container{
    margin-top: 30px;
}
.regdevice_container{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 100px;
    max-height: 680px;
    overflow-y: auto;
}
.contents_header_text{
    font-size: 14px;
    margin-top: -15px;
}
.img_style{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}
.notiftext_title{
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #000000;
    margin-bottom: 2px;
}
.notiftext{
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}
.input_header_style{
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #013042;
    margin-bottom: 2px;
}
.reg_btn{
    text-align: center;
    margin-top: 30px;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 500;
}
.reg_btn:hover{
    filter: brightness(90%);
}
.mandev_btn{
    text-align: center;
    width: 150px;
    height: 30px;
    margin-bottom: 5px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 500;
}
.mandev_btn:hover{
    filter: brightness(90%);
}
.dashboard_btn{
    text-align: center;
    width: 150px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffffff;
    border-color: #013042;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 500;
}
.dashboard_btn:hover{
    filter: brightness(90%);
}
.custom_input_form{
    background-color: #ffffff;
    height: 35px;
    width: 480px;
    text-decoration: none solid rgba(0, 0, 0, 0.87);
    border-color: rgba(1, 48, 66, 0.2) !important;
    word-spacing: 0px;
    letter-spacing: 0.131118px;
    font-size: 12px !important;
    box-sizing: border-box;
    outline: rgba(0, 0, 0, 0.87) none 0px;
}
.custom_input_form:focus{
    outline: none !important;
    box-shadow: none !important;
    border-color: rgba(1, 48, 66, 0.8) !important;
    border-width: 2.5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
}
::placeholder{
    font-size: 12px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 9998; /* Below the loading content */
}
.warning-msg {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
}
.loading-card {
    position: fixed;
    width: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 28px;
    filter: brightness(98%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.loading-text {
    font-family: CenturyGothic, sans-serif;
    text-align: center;
}
</style>